body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #232333 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background: #404155;

}

::-webkit-scrollbar-thumb {
  background: #717287;
  border-radius: 10px;
}

/* .gm-ui-hover-effect span{
  color: #fff !important;
  background: #fff !important;
}

.gm-style-iw{
  background-color: #232333 !important;
  color: #fff;
}

.gm-style-iw-tc{
  color: #232333 !important;
}

.gm-style-iw-d {overflow:visible !important; padding: 12px 14px 12px 0px !important} */
