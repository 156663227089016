.faq_section .faq_content p {
    font-size: 22px;
    font-weight: 400;
    color: #696CFF;
    text-align: center;
}

.faq_section .faq_content h2 {
    font-size: 32px;
    text-align: center;
    color: #fff;
    font-weight: 600;
    margin-top: 12px;
}

.faq_section .faq_content h6 {
    color: #9797A9;
    text-align: center;
    font-size: 18px;
    margin-top: 16px;
    font-weight: 400;
    margin-bottom: 74px;
}

.need_more_help_title h2 {
    font-size: 26px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    margin-top: 54px;
    margin-bottom: 54px;
}

.need_more_help_box {
    width: 100%;
    height: 163px;
    background-color: #2B2C40;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 4px 0px 10px 0px #00000033;
    text-decoration: none !important;
    cursor: pointer;
}

.need_more_help_box_icon svg {
    fill: #696CFF;
    font-size: 42px;
}

.need_more_help_box .need_more_help_box_text p {
    font-size: 24px;
    font-weight: 400;
    margin-top: 16px;
    color: #fff;
}

.faq_section_container {
    height: calc(100vh - 212px);
    overflow-Y: auto;
}